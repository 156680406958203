import * as React from "react"
import "./App.css"

import { ResponsiveLine } from "@nivo/line"
import * as idb from "idb-keyval" 

const millilitre = {
  name: "Millilitre",
  id: "ml",
  abr: "ml",
  convertTo: (amount) => amount,
  convertFrom: (amount) => amount,
}

const imperialFluidOunce = {
  name: "Imperial Fluid Ounce",
  id: "impFlOz",
  abr: "imp fl oz",
  convertTo: toImperialFluidOunce,
  convertFrom: fromImperialFluidOunce,
}

const usFluidOunce = {
  name: "US Fluid Ounce",
  id: "usFlOz",
  abr: "US fl oz",
  convertTo: toUSFluidOunce,
  convertFrom: fromUSFluidOunce,
}

const volumeUnits = [millilitre, imperialFluidOunce, usFluidOunce]

class App extends React.Component {
  constructor(props) {
    super(props)
    this.handleVolumeUnitChange = this.handleVolumeUnitChange.bind(this)
    this.handleAddWaterClick = this.handleAddWaterClick.bind(this)
    this.handleFrequencyViewChange = this.handleFrequencyViewChange.bind(this)
    this.handleShowOnGraphClick = this.handleShowOnGraphClick.bind(this)
    this.handleLogDeletion = this.handleLogDeletion.bind(this)
    this.state = {
      volumeUnit: millilitre,
      log: [],
      dayOfWeekOffset: 1,
      frequencyView: "daily",
      navigationOptions: ["daily", "weekly", "monthly", "yearly"],
      logIdCounter: 0,
      selectedInterval: null,
      userDailyTarget: 2000,
    }
  }

  componentDidMount() {
    // console.log("componentDidMount")
    idb.get('log').then(value => {
      if (value !== undefined) { 
        this.setState({log:value})
      }
    })
  
    idb.get('volumeUnit').then(value => {
      if (value !== undefined) {
        const unit = volumeUnits.find((x) => x.id === value)
        this.setState({volumeUnit: unit})
      }
    })

    idb.get('logIdCounter').then(value => {
      if (value !== undefined) {
        this.setState({logIdCounter: value})
      }
    })
  }

  addLog(currentLog, time, amount, prevFrequency) {
    // Base case
    if (prevFrequency === "daily") {
      currentLog.push({
        id: this.state.logIdCounter,
        time: time,
        amount: amount,
      })
      currentLog.sort((a, b) => b.time - a.time)
      
      const newLogCounter = this.state.logIdCounter + 1
      this.setState({ logIdCounter: newLogCounter})
      idb.set('logIdCounter', newLogCounter)
      return currentLog
    }

    const matchFound = currentLog.find(
      (logItem) => time >= logItem.startDate && time < logItem.endDate
    )

    // If the current time frequency is not found.
    if (typeof matchFound === "undefined") {
      var newFrequency, startDate, endDate
      switch (prevFrequency) {
        case null:
          newFrequency = "yearly"
          startDate = new Date(time.getFullYear(), 0, 1)
          endDate = new Date(time.getFullYear() + 1, 0, 1)
          break
        case "yearly":
          newFrequency = "monthly"
          startDate = new Date(time.getFullYear(), time.getMonth(), 1)
          endDate = new Date(time.getFullYear(), time.getMonth() + 1, 1)
          break
        case "monthly":
          newFrequency = "weekly"
          const day =
            time.getDay() !== 0 ? time.getDay() - this.state.dayOfWeekOffset : 6
          const startDay = time.getDate() - day
          startDate = new Date(time.getFullYear(), time.getMonth(), startDay)
          endDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + 7
          )
          break
        default:
          newFrequency = "daily"
          startDate = new Date(
            time.getFullYear(),
            time.getMonth(),
            time.getDate()
          )
          endDate = new Date(
            time.getFullYear(),
            time.getMonth(),
            time.getDate() + 1
          )
          break
      }

      currentLog.push({
        id: startDate.valueOf(),
        frequency: newFrequency,
        startDate: startDate,
        endDate: endDate,
        total: amount,
        values: this.addLog([], time, amount, newFrequency),
      })
      currentLog.sort((a, b) => b.startDate - a.startDate)
      return currentLog

      // Recursive step inside current bracket.
    } else {
      matchFound.total = matchFound.total + amount
      matchFound.values = this.addLog(
        matchFound.values,
        time,
        amount,
        matchFound.frequency
      )
      return currentLog
    }
  }

  // Recursively remove log with matching id.
  removeLog(log, deletedLog, frequency) {
    // Base case
    if (frequency === 'daily') {
      const index = log.findIndex(l => l.id === deletedLog.id) 
      log.splice(index, 1)
      return log
    }

    // Navigate tree
    const matchFound = log.find(
      logItem => deletedLog.time >= logItem.startDate && deletedLog.time < logItem.endDate
    )

    matchFound.total = matchFound.total - deletedLog.amount
    matchFound.values = this.removeLog(
      matchFound.values,
      deletedLog,
      matchFound.frequency
    )

    if (matchFound.values.length === 0) {
      log.splice(log.indexOf(matchFound), 1)
    }

    return log
  }

  handleVolumeUnitChange(e) {
    const unitId = e.target.value
    const unit = volumeUnits.find((x) => x.id === unitId)
    this.setState({ volumeUnit: unit })
    idb.set('volumeUnit', unitId)
  }

  handleAddWaterClick(time, amount) {
    amount = parseFloat(amount)
    if (isNaN(amount)) {
      console.log("Value is not a number")
    }

    const newLog = this.addLog(this.state.log, time, amount, null)
    this.setState({ log: newLog })
    idb.set('log', this.state.log)
  }

  handleFrequencyViewChange(direction) {
    const navigationOptions = this.state.navigationOptions
    var currentPage = navigationOptions.indexOf(this.state.frequencyView)
    if (direction === "back") {
      currentPage =
        currentPage > 0 ? currentPage - 1 : navigationOptions.length - 1
    } else {
      currentPage =
        currentPage < navigationOptions.length - 1 ? currentPage + 1 : 0
    }

    this.setState({ frequencyView: navigationOptions[currentPage] })
  }

  handleShowOnGraphClick(intvervalId) {
    this.setState({ selectedInterval: intvervalId })
  }

  handleLogDeletion(deletedLog) {
    const newLog = this.removeLog(this.state.log, deletedLog, null)
    this.setState({ log: newLog })
    idb.set('log', newLog)
  }

  render() {
    var selectedLog, graphLog, target, total

    if (this.state.log.length === 0) {
      selectedLog = []
      graphLog = null
      target = this.state.userDailyTarget
      total = 0
    } else {
      switch (this.state.frequencyView) {
        case "yearly":
          selectedLog = this.state.log
          break
        case "monthly":
          selectedLog = this.state.log.map((year) => year.values).flat()
          break
        case "weekly":
          selectedLog = this.state.log
            .map((year) => year.values.map((month) => month.values).flat())
            .flat()
          break
        default:
          selectedLog = this.state.log
            .map((year) =>
              year.values
                .map((month) => month.values.map((week) => week.values).flat())
                .flat()
            )
            .flat()
      }

      graphLog = selectedLog.find(
        (log) => log.startDate === this.state.selectedInterval
      )
      if (typeof graphLog === "undefined") {
        graphLog = selectedLog[0]
      }

      target =
        (this.state.userDailyTarget *
          Math.round(
            graphLog.endDate.getTime() - graphLog.startDate.getTime()
          )) /
        (1000 * 60 * 60 * 24)

      total = graphLog.total
    }

    return (
      <div className="App">
        <div className="Main-container">
          <div className="Graph-input-container">
            <Settings
              unit={this.state.volumeUnit}
              onVolumeUnitChange={this.handleVolumeUnitChange}
            />
            <Navigation
              frequencyView={this.state.frequencyView}
              onFrequencyViewChange={this.handleFrequencyViewChange}
            />
            <Display
              unit={this.state.volumeUnit}
              log={graphLog}
              total={total}
              target={target}
              frequencyView={this.state.frequencyView}
            />
            <Input
              unit={this.state.volumeUnit}
              onAddWaterClick={this.handleAddWaterClick}
              frequencyView={this.state.frequencyView}
            />
          </div>
          <Log
            unit={this.state.volumeUnit}
            log={selectedLog}
            frequencyView={this.state.frequencyView}
            handleShowOnGraphClick={this.handleShowOnGraphClick}
            handleLogDeletion={this.handleLogDeletion}
          />
        </div>
      </div>
    )
  }
}

class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.onVolumeUnitChange(e)
  }

  render() {
    const volumeUnitOptions = volumeUnits.map((unit) => (
      <option key={unit.id} value={unit.id}>
        {unit.name}
      </option>
    ))
    return <select onChange={this.handleChange} value={this.props.unit.id}>{volumeUnitOptions}</select>
  }
}

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
    this.handleForward = this.handleForward.bind(this)
  }

  handleBack(e) {
    this.props.onFrequencyViewChange("back")
  }

  handleForward(e) {
    this.props.onFrequencyViewChange("forward")
  }

  render() {
    return (
      <div>
        <button id="navigation-back" onClick={this.handleBack}>
          ←
        </button>
        {this.props.frequencyView}
        <button id="navigation-forward" onClick={this.handleForward}>
          →
        </button>
      </div>
    )
  }
}

class Display extends React.Component {
  render() {
    const data = this.props.log === null ? [] : this.props.log.values
    return (
      <div className="display">
        <Graph
          data={data}
          target={this.props.target}
          unit={this.props.unit}
          frequencyView={this.props.frequencyView}
        />
        <Progress
          current={this.props.total}
          target={this.props.target}
          unit={this.props.unit}
        />
      </div>
    )
  }
}

class Graph extends React.Component {
  constructor(props) {
    super(props)
    this.convertToChartData = this.convertToChartData.bind(this)
  }

  convertToChartData(data) {
    var getTime, getValue
    if (this.props.frequencyView === "daily") {
      getTime = (d) => d.time
      getValue = (d) => d.amount
    } else {
      getTime = (d) => d.startDate
      getValue = (d) => d.total
    }

    var chartData = []
    var rollingTotal = 0
    for (const d of data.reverse()) {
      rollingTotal = rollingTotal + getValue(d)
      chartData.push({
        amount: tryConvert(getValue(d), this.props.unit.convertTo),
        x: getTime(d),
        y: tryConvert(rollingTotal, this.props.unit.convertTo),
      })
    }

    return chartData
  }

  render() {
    var chartData = [
      {
        id: this.props.frequencyView,
        data: this.convertToChartData(this.props.data),
      },
    ]

    const options = {
      daily: { format: "%H:%M", tickValues: "every 1 hour" },
      weekly: { format: "%d/%m/%Y", tickValues: "every 1 day" },
      monthly: { format: "%d/%m/%Y", tickValues: "every 7 days" },
      yearly: { format: "%m/%Y", tickValues: "every 1 month" },
    }

    return (
      // <ResponsiveContainer width="95%" height={400}>
      //   <BarChart data={this.props.data} width={width} height={height} margin={margin} >
      //     <Bar type="monotone" dataKey={getY} stroke="#8884d8" />
      //     <XAxis dataKey={getX} />
      //     <YAxis />
      //   </BarChart>
      // </ResponsiveContainer>

      // <ResponsiveBar data={this.props.data} indexBy={getX} keys={getY} />
      <div id="graph">
        <ResponsiveLine
          data={chartData}
          margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
          xScale={{
            type: "time",
            format: "native",
            // useUTC: false,
            // precision: 'hour',
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            min: "0",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: options[this.props.frequencyView].format,
            tickValues: options[this.props.frequencyView].tickValues,
            // legend: 'Time',
            // legendOffset: -12,
          }}
          enableGridX={false}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'count',
            // legendOffset: -40,
            // legendPosition: 'middle'
          }}
          colors={{ scheme: "nivo" }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh={true}
          markers={[
            {
              axis: "y",
              value: tryConvert(this.props.target, this.props.unit.convertTo),
              lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
              legend: "Target",
              legendOrientation: "horizontal",
            },
          ]}
        />
      </div>
    )
  }
}

class Progress extends React.Component {
  render() {
    const current = tryConvert(this.props.current, this.props.unit.convertTo)
    const target = tryConvert(this.props.target, this.props.unit.convertTo)
    const progressPercentage = Math.round((current * 100) / target)
    return (
      <p>
        {current} {this.props.unit.abr} / {target} {this.props.unit.abr} ·{" "}
        {progressPercentage}%
      </p>
    )
  }
}

// Input Section
class Input extends React.Component {
  render() {
    return (
      <div>
        <InputButton
          colour="Blue"
          amount="50"
          unit={this.props.unit}
          onAddWaterClick={this.props.onAddWaterClick}
        />
        <InputButton
          colour="Red"
          amount="100"
          unit={this.props.unit}
          onAddWaterClick={this.props.onAddWaterClick}
        />
        <InputButton
          colour="Purple"
          amount="250"
          unit={this.props.unit}
          onAddWaterClick={this.props.onAddWaterClick}
        />
        <CustomInputButton
          unit={this.props.unit}
          onAddWaterClick={this.props.onAddWaterClick}
        />
      </div>
    )
  }
}

class InputButton extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    const time = new Date()
    this.props.onAddWaterClick(time, this.props.amount)
  }

  render() {
    const amount = tryConvert(this.props.amount, this.props.unit.convertTo)
    const styleClass = "Input-button " + this.props.colour

    return (
      <button onClick={this.handleClick} className={styleClass}>
        {amount} {this.props.unit.abr}
      </button>
    )
  }
}

class CustomInputButton extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)

    this.state = { time: new Date().getTime() }
  }

  handleClick(e) {
    const value = tryConvert(this.state.value, this.props.unit.convertFrom)
    this.props.onAddWaterClick(this.state.time, value)
  }

  handleTimeChange(e) {
    // TODO Input Validation
    this.setState({ time: new Date(e.target.value) })
  }

  handleValueChange(e) {
    // TODO: Input Validation
    this.setState({ value: e.target.value })
  }

  render() {
    return (
      <div className="custom-input-area">
        <input
          type="datetime-local"
          placeholder="yyyy-mm-ddT--:--"
          onChange={this.handleTimeChange}
        ></input>
        <input onChange={this.handleValueChange} type="text" id="custom-input"></input>
        {this.props.unit.abr}
        <button onClick={this.handleClick} id="custom-add-button">+</button>
      </div>
    )
  }
}

// Log Section
class Log extends React.Component {
  constructor(props) {
    super(props)
    this.handleShowOnGraphClick = this.handleShowOnGraphClick.bind(this)
  }

  sameDay(a, b) {
    return (
      a.getDate() === b.getDate() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() === b.getFullYear()
    )
  }

  handleShowOnGraphClick(e) {
    this.props.handleShowOnGraphClick(e)
  }

  createLogValue(logItem, frequency) {
    const options = {
      daily: { hour: "2-digit", minute: "2-digit" },
      weekly: { day: "2-digit", month: "long", year: "numeric" },
      monthly: { day: "2-digit", month: "long", year: "numeric" },
      yearly: { month: "long", year: "numeric" },
    }

    var label, amount
    if (frequency === "daily") {
      label = logItem.time
      amount = logItem.amount
    } else {
      label = logItem.startDate
      amount = logItem.total
    }

    label = label.toLocaleString("default", options[frequency])

    return (
      <LogValue
        key={logItem.id + frequency}
        label={label}
        amount={amount}
        frequencyView={this.props.frequencyView}
        handleLogDeletion={() => this.props.handleLogDeletion(logItem)}
        unit={this.props.unit}
      />
    )
  }

  render() {
    const log = this.props.log

    const options = {
      daily: { day: "2-digit", month: "long", year: "numeric" },
      weekly: { day: "2-digit", month: "long", year: "numeric" },
      monthly: { month: "long", year: "numeric" },
      yearly: { year: "numeric" },
    }

    if (log.length === 0) {
      return ""
    }

    const logItems = log.map((logGroup) => (
      <div key={logGroup.startDate.valueOf()} className="Time-interval">
        <div className="Time-interval-value">
          {logGroup.startDate.toLocaleString(
            "default",
            options[logGroup.frequency]
          )}
          <button
            key={logGroup.startDate.valueOf()}
            onClick={() => this.handleShowOnGraphClick(logGroup.startDate)}
          >
            Show on graph
          </button>
        </div>
        <div className="Time-interval-group">
          {logGroup.values.map((logItem) =>
            this.createLogValue(logItem, logGroup.frequency)
          )}
        </div>
      </div>
    ))

    return <div className="Log-container">{logItems}</div>
  }
}

class LogValue extends React.Component {
  render() {
    const amount = tryConvert(this.props.amount, this.props.unit.convertTo)
    const button =
      this.props.frequencyView === "daily" ? (
        <input type="button" id="edit-button" onClick={this.props.handleLogDeletion}></input>
      ) : (
        <div></div>
      )
    return (
      <li>
        <div className="Log-value">
          <div className="Log-time">{this.props.label}</div>
          <div className="Log-amount">
            {amount} {this.props.unit.abr}
          </div>
          {button}
        </div>
      </li>
    )
  }
}

function toUSFluidOunce(millitre) {
  return millitre * 0.033814023
}

function fromUSFluidOunce(usFluidOunce) {
  return usFluidOunce * 29.57352930173
}

function toImperialFluidOunce(millilitre) {
  return millilitre * 0.03519508
}

function fromImperialFluidOunce(impFluidOunce) {
  return impFluidOunce * 28.4130625
}

// Code from React Tutorial
function tryConvert(volume, convert) {
  const input = parseFloat(volume)
  if (Number.isNaN(input)) {
    return ""
  }
  const output = convert(input)
  const rounded = Math.round(output * 100) / 100
  return rounded.toString()
}

export default App
